<template>
	<div class="container">
		<LoadingScreen :enabled="!ready" />

		<h1>Group settings</h1>
    <br>

	<!-- Inputting info like name, nickname and bio -->
	<input type="text" placeholder="Group name" v-model="model.name" maxlength=50 pattern="[1-9]+" autofocus class="nameInput" disabled>
	<textarea rows=4 placeholder="Group description" maxlength=400 v-model="model.description"></textarea>

    <!-- Settings for group visibility -->
    <Switcher id="groupVisibility" label="Private group" v-model="model.private"></Switcher>

    <!-- Uploading a group profile pic -->
    <div class="profileImgContainer">
      <ImageUpload preview="group" :imageUrl="model.profile_img_url" @changed="profileImgChanged" />
      <h3>Group image</h3>
    </div>

		<!-- Uploading a cover image for their profile -->
		<div class="coverImgContainer">
			<ImageUpload preview="cover" :imageUrl="model.cover_img_url" @changed="coverImgChanged" />
			<h3>Choose a cover photo</h3>
		</div>

		<div class="buttonContainer">
			<button @click="save">SAVE</button>
			<button @click="redirect">BACK</button>
		</div>
	</div>
</template>


<script>
// Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import ImageUpload from "@/components/ImageUpload";
import LoadingScreen from "@/components/LoadingScreen";
import Switcher from "@/components/Switcher";

	export default {
		name: "ProfileSetup",
		components: {Switcher, ImageUpload, LoadingScreen},
		props: {
      	group: {
				type: Object,
				default: false
			}
		},
		data() {
			return {
				model: {
					name: "",
					description: "",
					private: false,
					profile_img: false,// form data
          			profile_img_url: "",
					cover_img: false,// form data
					cover_img_url: ""
				},
				ready: true
			};
		},
		mounted() {
			// Find out if we got group data (this.group) and sync it to UI if we did
			if (this.group !== false) {
				const group = this.$formatter.replaceEmpty(this.group);

				this.model.name = group.name;
				this.model.description = group.description;
				this.model.private = Boolean(group.private);
				this.model.cover_img_url = group.img_url;
				this.model.profile_img_url = group.profile_img_url;
			}
		},
		computed: {
			...mapStores(useUserStore),
		},
		methods: {
      profileImgChanged(data) {
				this.model.profile_img = data;
			},
			coverImgChanged(data) {
				this.model.cover_img = data;
			},
			async save() {
				let checkChars = (s) => {
					try {
						return /^[a-z'\. žđščć0-9\-]+$/i.test(s);
					}
					catch {
						return false;
					}
				};
				let checkLength = (s, min, max) => {
					try {
						return (s.length >= min && s.length <= max);
					}
					catch {
						return false;
					}
				};

				this.ready = false;

				// Validate all inputs
				if (this.model.name !== "" && (!checkLength(this.model.name, 3, 50) || !checkChars(this.model.name))) {
					this.ready = true;
					return;
				}
				if (!checkLength(this.model.description, 0, 400)) {
					this.ready = true;
					return;
				}

				// Upload image (as needed)
				let coverImgUrl = false;
				if (this.model.cover_img !== false) {
					let response = await this.$api.uploadImage(this.model.cover_img, this.userStore.accessToken);

					if (response.success === true)
						coverImgUrl = response.url;
				}
				// Upload image (as needed)
				let profileImgUrl = false;
				if (this.model.profile_img !== false) {
					let response = await this.$api.uploadImage(this.model.profile_img, this.userStore.accessToken);

					if (response.success === true)
            			profileImgUrl = response.url;
				}


				// Build the new settings object
				let newSettings = {
					groupId: this.group.id_group,
					userId: this.userStore.user.id,
					name: this.model.name,
					description: this.model.description,
					private: this.model.private
				};

				if (coverImgUrl !== false)
					newSettings["img_url"] = coverImgUrl;

				if (profileImgUrl !== false)
					newSettings["profile_img_url"] = profileImgUrl;

				// Save new settings to backend
				await this.$api.updateGroupSettings(newSettings, this.userStore.accessToken);

				this.ready = true;
				this.redirect();
			},
			redirect() {
				this.$router.push({path: "/group/"+this.group.id_group});
			}
		}
	}
</script>


<style lang="scss" scoped>
  .switch-outer {
    color: var(--text);
  }
	.container {
		box-sizing: border-box;
		width: 100%;
		height: auto;
		padding: 15px;
		max-width: 600px;
		min-width: 300px;
		background-color: var(--dark);
		border-radius: 15px;

		& > * {
			margin-bottom: 20px;
		}
		h1 {
			width: 10em;
			margin: 0 auto;
			text-align: center;
			color: var(--light);
		}
		h3 {
			margin: 0;
			font-size: 1em;
			font-weight: 400;
			color: var(--medium);
		}
		.typeContainer {
			.labelContainer {
				@extend .pointer;
				@extend .noselect;
				@extend .nodrag;
				display: inline-block;
				margin-right: 10px;

				input {
					opacity: 0;
				}
				div {
					padding: 10px;
					background-color: var(--light);
					border-radius: 10px;

					img {
						width: 1em;
						height: 1em;
					}
				}
				input:checked ~ div {
					@include gradient;
				}
			}
		}
		.profileImgContainer {
			text-align: center;
		}
		.locationContainer {
			display: flex;
			align-items: center;

			* {
				display: inline-block;
			}
			img {
				@extend .pointer;
				margin-left: 10px;

				&:hover {
					@include popup(1.1);
				}
			}
			.locationInput {
				padding-top: 5px;
				padding-bottom: 5px;
				margin-left: 10px;
				animation: fadeIn .3s, popup .3s;
			}
		}
		.coverImgContainer {
			width: 100%;
			text-align: center;
		}
		.buttonContainer {
			text-align: center;
			button {
				@include button;
				@include gradient;
				margin: 10px;
				font-size: 1.2em;
				font-weight: 200;
			}
		}

		input[type="text"], textarea {
			@include remove-outline;
			@include text-input;
			display: block;
			padding: 15px;
			width: 60%;
		}
		textarea {
			@include scrollbars;
			resize: none;
		}
		.nameInput {
			@include placeholder {
				font-weight: bold;
			};
		}
	}
</style>
