<template>
	<div class="main">
		<GroupSetup v-if="ready" class="form" :group="group" :user="user" />
	</div>
</template>


<script>
// Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import GroupSetup from "@/components/GroupSetup";

export default {
	name: "GroupSetupView",
	components: { GroupSetup },
	data() {
		return {
		editing: this.$route.query.editing,
		ready: false,
		user: false,
		group: false
		};
	},
	async mounted() {
		const groupId = this.$route.params.group;
		const response = await this.$api.getGroupInfo(groupId, ['info'], this.userStore.accessToken);

		if (response.success === true) {
		this.group = Object.assign(response.info, {id_group: groupId});
		this.ready = true;
	}
	},
	computed: {
		...mapStores(useUserStore),
	}
}
</script>


<style lang="scss" scoped>
	.main {
		@include gradient;

		height: 100vh;

		display: grid;
		grid-template-columns: 0.8fr 1.4fr 0.8fr;
		grid-template-rows: 0.3fr auto 1fr;
		gap: 0px 0px;
		grid-template-areas:
			". . ."
			". form ."
			". . .";

		.form {
			@include center;
			@include box-shadow();
			grid-area: form;
			width: 100%;
		}

		@include respond-to('medium') {
			grid-template-columns: 0.3fr 1.4fr 0.3fr;
		}
		@include respond-to('small') {
			grid-template-columns: 0.1fr 1.4fr 0.1fr;
		}
	}
</style>
